<script lang="ts">
	import { page } from "$app/stores";
	import { version } from "$app/environment";
	import DDate from "./Date.svelte";
	import Logo from "./Logo.svelte";
</script>

<footer class="container-fluid text-center pt-5">
	<div class="container">
		<div class="row text-start pb-5">
			<div class="col-12 col-md text-center text-md-start mb-4 mb-md-0">
				<div style="height: 2rem">
					<Logo fill={$page.data.theme?.footerLogoColor || "#000"} />
				</div>
				<small class="d-block mb-3 text-muted">© 2022 - {new Date().getFullYear()}</small>
				<small class="d-block mb-3 text-muted">Version vom<br /><DDate date={parseInt(version)} /></small>
			</div>
			{#if $page.data.organization}
				<div class="col-12 col-sm-6 col-md-4 col-lg">
					<h5>Anmeldung</h5>
					<ul class="list-unstyled text-small">
						<li><a class="link-dark text-decoration-none" href="https://{$page.data.organization.slug}.admin.{$page.data.organization.domain}" target="_blank" rel="noreferrer">für Veranstalter</a></li>
						<li><a class="link-dark text-decoration-none" href="https://{$page.data.organization.slug}.vvk.{$page.data.organization.domain}" target="_blank" rel="noreferrer">für Vorverkaufsstellen</a></li>
					</ul>
				</div>
			{/if}
			<div class="col-12 col-sm-6 col-md-4 col-lg">
				<h5>Rechtliches</h5>
				<ul class="list-unstyled text-small">
					<li class="mb-1"><a class="link-dark text-decoration-none" href="/legal/privacy">Datenschutzerklärung</a></li>
					<li class="mb-1"><a class="link-dark text-decoration-none" href="/legal/terms">AGB</a></li>
					<li class="mb-1"><a class="link-dark text-decoration-none" href="/legal/imprint">Impressum</a></li>
				</ul>
			</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg">
				<h5>Kontakt</h5>
				<ul class="list-unstyled text-small">
					<li class="mb-1"><a class="link-dark text-decoration-none" href="https://dein-{$page.data.isTestMode ? 'demo' : 'ticket'}.shop/contact{$page.data.organization ? `?org=${encodeURIComponent($page.data.organization.name)}` : ''}">zu Dein-Ticket.Shop</a></li>
					{#if $page.data.organization}
						<li class="mb-1"><a class="link-dark text-decoration-none" href="/about">zum Veranstalter</a></li>
					{/if}
				</ul>
			</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg">
				<h5>Über</h5>
				<ul class="list-unstyled text-small">
					{#if $page?.data?.organization?.name}<li class="mb-1"><a class="link-dark text-decoration-none" href="/about">{$page.data.organization.name}</a></li>{/if}
					<li class="mb-1"><a class="link-dark text-decoration-none" href="https://about.dein-{$page.data.isTestMode ? 'demo' : 'ticket'}.shop">Dein-Ticket.Shop</a></li>
				</ul>
			</div>
		</div>
	</div>
</footer>

<style>
	.container-fluid {
		background-color: var(--primary-color, #ffd400);
		color: var(--primary-color-text, #000);
	}

	.link-dark {
		color: var(--primary-color-text, #000) !important;
	}
	.text-muted {
		color: var(--primary-color-text, #000) !important;
	}
</style>
