<script lang="ts">
	import { page } from "$app/stores";
</script>

{#if $page.data.isTestMode}
	<div class="test-mode-info-container">
		<span class="test-mode-info">Test-Modus</span>
	</div>
{/if}

<style>
	.test-mode-info-container {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
		border-top: 3px solid #c84800;
		display: flex;
		justify-content: center;
		pointer-events: none;
	}
	.test-mode-info {
		background-color: #c84800;
		color: white;
		padding: 0 0.5rem 0.2rem;
		text-align: center;
		font-weight: bold;
		font-size: 80%;
		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		pointer-events: none;
	}
</style>
