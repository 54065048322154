<script lang="ts">
	import { onMount } from "svelte";
	import Time from "svelte-time";
	import { browser } from "$app/environment";
	import "dayjs/locale/de";
	import { LOCALE, TIMEZONE } from "../constants/Timezones";
	import { dayjs } from "svelte-time";
	dayjs.locale("de");

	export let date: number | string | Date;

	export let mode: "fulldate" | "dayOfWeek" | "date" | "datetime" | "time" | "countdown" | "dateWithoutYear" | "relative" = "datetime";
	export let span = true;

	let dateString: string;
	let actuallyCountingDown = false;

	function loadCountdownTimeString() {
		const now = new Date();
		const diff = new Date(date).getTime() - now.getTime();

		if (diff <= 0) {
			dateString = "abgelaufen";
			if (countdownInterval) {
				clearInterval(countdownInterval);
			}
			if (browser && actuallyCountingDown) {
				setTimeout(() => {
					window?.location?.reload();
				}, 1500);
			}
			return;
		} else {
			actuallyCountingDown = true;
		}

		const diffSeconds = Math.floor(diff / 1000);
		const diffMinutes = Math.floor(diffSeconds / 60);
		const diffHours = Math.floor(diffMinutes / 60);
		const diffDays = Math.floor(diffHours / 24);

		// make (hh:)mm:ss
		const hours = diffHours % 24;
		const minutes = diffMinutes % 60;
		const seconds = diffSeconds % 60;

		const paddedMinutes = minutes.toString().padStart(2, "0");
		const paddedSeconds = seconds.toString().padStart(2, "0");

		// only show fields that are not 0
		if (diffDays > 0) {
			dateString = `noch ${diffDays} Tag${diffDays > 1 ? "e" : ""} ${hours}:${paddedMinutes}:${paddedSeconds}`;
		} else if (diffHours > 0) {
			dateString = `noch ${hours}:${paddedMinutes}:${paddedSeconds}`;
		} else if (diffMinutes > 0) {
			dateString = `noch ${minutes}:${paddedSeconds}`;
		} else {
			dateString = `noch ${seconds} Sekunde${seconds != 1 ? "n" : ""}`;
		}
	}

	$: {
		if (typeof date === "string" || typeof date === "number") {
			date = new Date(date);
		}
		try {
			switch (mode) {
				case "date":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "time":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						hour: "2-digit",
						minute: "2-digit",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "relative":
				case "datetime":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						hour: "2-digit",
						minute: "2-digit",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "fulldate":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						weekday: "long",
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "dayOfWeek":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						weekday: "long",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "dateWithoutYear":
					dateString = new Intl.DateTimeFormat(LOCALE, {
						month: "2-digit",
						day: "2-digit",
						timeZone: TIMEZONE,
					}).format(date);
					break;
				case "countdown":
					loadCountdownTimeString();
					break;
			}
		} catch (e) {
			console.error(e);
			dateString = "Fehler beim Formatieren des Datums";
		}
	}

	let countdownInterval: NodeJS.Timeout | null = null;
	onMount(() => {
		if (mode === "countdown") {
			countdownInterval = setInterval(() => {
				loadCountdownTimeString();
			}, 1000);

			return () => {
				if (countdownInterval) {
					clearInterval(countdownInterval);
				}
			};
		}
	});
</script>

{#if mode == "relative"}
	<Time relative title={dateString} timestamp={date} />
{:else if span}
	<span>{dateString}</span>
{:else}
	{dateString}
{/if}
