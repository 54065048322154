<script>
	import "../app.scss";
	import TestModeInfo from "$myLib/components/TestModeInfo.svelte";
	import { page } from "$app/stores";
	import Footer from "$myLib/components/Footer.svelte";
	import { onMount } from "svelte";
	import { beforeNavigate } from "$app/navigation";
	import RobotsAllowed from "$myLib/components/RobotsAllowed.svelte";
	import { KnownImages } from "$myLib/helpers/KnownImages";

	onMount(async () => {
		// @ts-ignore
		window.bootstrap = await import("bootstrap/dist/js/bootstrap.esm");
	});

	beforeNavigate(() => {
		// collapse the navbar on navigation
		for (const component of document.querySelectorAll(".navbar-collapse")) {
			try {
				// @ts-ignore
				window.bootstrap.Collapse.getInstance(component).hide();
			} catch {
				//
			}
		}
	});

	const apex = $page.url.hostname.split(".").reverse().splice(0, 2).reverse().join(".");
</script>

<RobotsAllowed />

<nav class="navbar navbar-expand-lg bg-body-light position-sticky top-0 navbar header-container py-3">
	<div class="container">
		<a href="https://{apex}" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
			<img src={KnownImages.LOGO_FULL_COLOR_TRANSPARENT} alt="Logo" class="img-fluid me-3" style="max-height: 2rem;" />
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="nav navbar-nav ms-auto mb-2 mb-lg-0 nav-underline">
				<li class="nav-item">
					<a class="nav-link" href="https://{apex}">Alle Veranstaltungen</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/legal/imprint" class:active={$page.url.pathname === "/legal/imprint"}>Impressum</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<div class="app">
	<main>
		<slot />
	</main>
</div>

<TestModeInfo />

<Footer />

<style>
	.header-container {
		z-index: 1000;
		background-color: var(--bs-body-bg);
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}
</style>
