<script lang="ts">
	export let fill: string;
</script>

<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2000 600" style="enable-background:new 0 0 2000 600;" xml:space="preserve" {fill}>
	<g>
		<g>
			<path
				d="M83.91,363.79c-11.21,0-21.36-2.73-30.44-8.18c-9.09-5.45-16.32-12.76-21.7-21.92c-5.38-9.16-8.07-19.42-8.07-30.78
			c0-11.21,2.69-21.43,8.07-30.67c5.37-9.24,12.68-16.55,21.92-21.92c9.24-5.37,19.61-8.07,31.12-8.07c5.91,0,11.55,0.8,16.93,2.39
			c5.37,1.59,10.49,3.9,15.34,6.93v-48.39l31.12-5.23V362.2h-30.67v-9.77C107.84,360.01,96.63,363.79,83.91,363.79z M88.9,337.21
			c5.6,0,10.83-0.98,15.68-2.95c4.85-1.97,9.01-4.77,12.5-8.41v-46.12c-3.49-3.48-7.65-6.17-12.5-8.07
			c-4.85-1.89-10.07-2.84-15.68-2.84c-6.51,0-12.38,1.48-17.61,4.43c-5.23,2.95-9.35,7.01-12.38,12.15
			c-3.03,5.15-4.54,10.9-4.54,17.27c0,6.67,1.55,12.57,4.66,17.72c3.1,5.15,7.23,9.24,12.38,12.27
			C76.56,335.7,82.39,337.21,88.9,337.21z" />
			<path
				d="M230.44,364.47c-11.81,0-22.53-2.76-32.15-8.29c-9.62-5.53-17.23-12.87-22.83-22.04c-5.61-9.16-8.41-19.5-8.41-31.01
			c0-11.36,2.69-21.7,8.07-31.01c5.37-9.31,12.68-16.7,21.92-22.15c9.24-5.45,19.46-8.18,30.67-8.18c11.36,0,21.47,2.77,30.33,8.29
			c8.86,5.53,15.82,13.06,20.9,22.6c5.07,9.54,7.61,20.45,7.61,32.71v8.18h-87.69c1.36,4.7,3.63,8.94,6.82,12.72
			c3.18,3.79,7.04,6.7,11.59,8.75c4.54,2.04,9.46,3.07,14.77,3.07c5.15,0,10-0.8,14.54-2.39c4.54-1.59,8.41-3.9,11.59-6.93
			l20.45,18.63c-7.27,5.91-14.73,10.22-22.38,12.95C248.58,363.11,239.98,364.47,230.44,364.47z M198.41,291.32h57.71
			c-1.21-4.69-3.22-8.82-6.02-12.38c-2.8-3.56-6.17-6.32-10.11-8.29c-3.94-1.97-8.18-2.95-12.72-2.95c-4.85,0-9.24,0.95-13.18,2.84
			c-3.94,1.9-7.23,4.62-9.88,8.18C201.55,282.27,199.62,286.47,198.41,291.32z" />
			<path
				d="M321.09,229.75c-4.85,0-9.01-1.74-12.5-5.23c-3.49-3.48-5.23-7.72-5.23-12.72c0-4.85,1.74-8.97,5.23-12.38
			c3.48-3.41,7.65-5.11,12.5-5.11c5,0,9.2,1.7,12.61,5.11c3.41,3.41,5.11,7.54,5.11,12.38c0,5-1.7,9.24-5.11,12.72
			C330.29,228.01,326.09,229.75,321.09,229.75z M305.64,362.2V243.84h31.12V362.2H305.64z" />
			<path
				d="M361.3,362.2V243.84h31.12v9.77c9.09-8.03,20.22-12.04,33.4-12.04c9.09,0,17.15,2.01,24.2,6.02
			c7.04,4.01,12.53,9.54,16.47,16.58c3.94,7.04,5.91,15.19,5.91,24.42v73.61h-30.9v-68.84c0-7.72-2.24-13.82-6.7-18.29
			c-4.47-4.47-10.49-6.7-18.06-6.7c-5.3,0-10,1.02-14.09,3.07c-4.09,2.04-7.5,4.96-10.22,8.75v82.01H361.3z" />
		</g>
		<g>
			<path
				d="M1141.64,274.16c-3.94-1.97-8.18-2.95-12.72-2.95c-4.85,0-9.24,0.95-13.18,2.84c-3.94,1.9-7.23,4.62-9.88,8.18
			c-2.65,3.56-4.58,7.76-5.79,12.61h57.71c-1.21-4.69-3.22-8.82-6.02-12.38C1148.94,278.9,1145.57,276.13,1141.64,274.16z" />
			<path
				d="M1363.84,299.2c-0.01-52.57,38.63-96.01,89.03-103.81l-0.02-138.92c0.01-17.81-14.55-32.37-32.37-32.37l-922.41-0.01
			c-17.82,0-32.38,14.56-32.36,32.37l-0.02,138.92c50.4,7.81,89.03,51.24,89,103.83c0.01,52.58-38.61,96.02-89,103.82l-0.01,138.91
			c0.01,17.82,14.56,32.36,32.37,32.37h922.43c17.82,0.01,32.36-14.56,32.37-32.35l0.01-138.92
			C1402.46,395.22,1363.86,351.8,1363.84,299.2z M1154.67,50.35h33.12v63.56h-33.12V50.35z M1154.67,152.74
			c0-0.06,0.01-0.11,0.01-0.17v-16.4h33.12v63.56h-33.12v-16.29c0-0.1-0.01-0.19-0.01-0.28L1154.67,152.74z M762.92,235.77h-53.62
			v129.95h-32.94V235.77h-53.62v-29.08h140.17V235.77z M810.18,365.72h-31.12V247.36h31.12V365.72z M807.11,228.04
			c-3.41,3.49-7.61,5.23-12.61,5.23c-4.85,0-9.01-1.74-12.5-5.23c-3.49-3.48-5.22-7.72-5.22-12.72c0-4.85,1.74-8.97,5.22-12.38
			c3.48-3.41,7.65-5.11,12.5-5.11c5,0,9.2,1.7,12.61,5.11s5.11,7.54,5.11,12.38C812.22,220.32,810.52,224.56,807.11,228.04z
			 M864.47,324.03c2.88,5.23,6.74,9.35,11.59,12.38c4.85,3.03,10.37,4.54,16.58,4.54c5.3,0,10.26-1.14,14.88-3.41
			c4.62-2.27,9.05-5.68,13.29-10.22l18.63,19.54c-6.21,6.67-13.48,11.85-21.81,15.56c-8.33,3.71-17.19,5.57-26.58,5.57
			c-11.51,0-21.92-2.69-31.24-8.06c-9.32-5.37-16.74-12.72-22.26-22.04c-5.53-9.31-8.29-19.8-8.29-31.47
			c0-11.51,2.73-21.88,8.18-31.12c5.45-9.24,12.87-16.58,22.26-22.04c9.39-5.45,19.84-8.18,31.35-8.18c9.54,0,18.59,1.82,27.15,5.45
			c8.55,3.64,15.86,8.86,21.92,15.68l-18.86,20.22c-4.39-4.85-8.97-8.41-13.74-10.68c-4.77-2.27-9.96-3.41-15.56-3.41
			c-6.06,0-11.51,1.48-16.36,4.43c-4.85,2.95-8.63,7.01-11.36,12.15c-2.73,5.15-4.09,11.06-4.09,17.72
			C860.16,313.01,861.59,318.8,864.47,324.03z M1036.68,365.72l-49.98-53.84v53.84h-31.12V206.69l31.12-5.23v95.65l51.34-49.75
			h35.21l-56.11,54.07l59.52,64.29H1036.68z M1187.8,501.07v30.41v16.58h-33.12v-16.46c0-0.04-0.01-0.07-0.01-0.11l0.01-30.41
			v-16.57h33.12V501.07z M1187.79,445.91v16.34h-16.38c-0.06,0-0.11,0.01-0.16,0.01c-0.06,0-0.11-0.01-0.16-0.01h-16.42v-63.56
			h16.41c0.06,0,0.11-0.01,0.17-0.01c0.06,0,0.11,0.01,0.16,0.01h16.38v16.45c0,0.04,0.01,0.08,0.01,0.12l0.01,30.42
			C1187.8,445.75,1187.79,445.83,1187.79,445.91z M1188.21,317.1h-87.69c1.36,4.7,3.63,8.94,6.82,12.72
			c3.18,3.79,7.04,6.7,11.59,8.75c4.54,2.04,9.46,3.07,14.77,3.07c5.15,0,10-0.8,14.54-2.39c4.54-1.59,8.41-3.9,11.59-6.93
			l20.45,18.63c-7.27,5.91-14.73,10.22-22.38,12.95c-7.65,2.73-16.24,4.09-25.79,4.09c-11.81,0-22.53-2.76-32.15-8.29
			c-9.62-5.53-17.23-12.87-22.83-22.04c-5.61-9.16-8.41-19.5-8.41-31.01c0-11.36,2.69-21.7,8.07-31.01
			c5.37-9.31,12.68-16.7,21.92-22.15c9.24-5.45,19.46-8.18,30.67-8.18c11.36,0,21.47,2.77,30.33,8.29
			c8.86,5.53,15.82,13.06,20.9,22.6c5.07,9.54,7.61,20.45,7.61,32.71V317.1z M1284.99,273.48h-34.08v52.48
			c0,5.45,1.25,9.35,3.75,11.7c2.5,2.35,6.7,3.52,12.61,3.52c3.18,0,6.02-0.19,8.52-0.57c2.5-0.38,5.26-1.02,8.29-1.93v25.44
			c-3.18,1.06-7.12,1.89-11.81,2.5c-4.7,0.6-8.86,0.91-12.5,0.91c-13.03,0-22.91-2.99-29.65-8.97
			c-6.74-5.98-10.11-14.73-10.11-26.24v-58.84h-24.31v-26.13h24.31v-30.22l30.9-7.04v37.26h34.08V273.48z" />
		</g>
		<g>
			<path
				d="M1509.21,364.47c-10.6,0-20.56-1.4-29.87-4.2c-9.32-2.8-17.38-6.78-24.2-11.93l15.45-20.9
			c6.82,4.54,13.36,7.95,19.65,10.22c6.28,2.27,12.53,3.41,18.74,3.41c6.96,0,12.53-1.17,16.7-3.52c4.16-2.35,6.25-5.41,6.25-9.2
			c0-3.03-1.17-5.45-3.52-7.27c-2.35-1.82-6.1-3.1-11.25-3.86l-22.72-3.41c-11.66-1.82-20.56-5.64-26.69-11.47
			c-6.13-5.83-9.2-13.52-9.2-23.06c0-7.72,2.01-14.39,6.02-19.99c4.01-5.6,9.73-9.92,17.15-12.95c7.42-3.03,16.05-4.54,25.9-4.54
			c8.78,0,17.3,1.21,25.56,3.63c8.25,2.42,16.02,6.13,23.29,11.13l-14.99,20.22c-6.51-4.09-12.76-7.04-18.74-8.86
			c-5.99-1.82-11.93-2.73-17.83-2.73c-5.45,0-9.92,1.06-13.4,3.18c-3.49,2.12-5.23,4.92-5.23,8.41c0,3.18,1.21,5.68,3.63,7.5
			c2.42,1.82,6.51,3.18,12.27,4.09l22.72,3.18c11.81,1.67,20.82,5.45,27.04,11.36c6.21,5.91,9.31,13.4,9.31,22.49
			c0,7.58-2.27,14.28-6.82,20.11c-4.54,5.83-10.72,10.45-18.52,13.86C1528.1,362.77,1519.2,364.47,1509.21,364.47z" />
			<path
				d="M1576.91,362.2V203.17l31.12-6.82v57.25c9.09-8.03,20.22-12.04,33.4-12.04c9.09,0,17.15,2.01,24.2,6.02
			c7.04,4.01,12.53,9.54,16.47,16.58c3.94,7.04,5.91,15.19,5.91,24.42v73.61h-30.9v-68.84c0-7.72-2.24-13.82-6.7-18.29
			c-4.47-4.47-10.49-6.7-18.06-6.7c-5.3,0-10,1.02-14.09,3.07c-4.09,2.04-7.5,4.96-10.22,8.75v82.01H1576.91z" />
			<path
				d="M1769.56,364.47c-11.81,0-22.49-2.73-32.03-8.18c-9.54-5.45-17.12-12.84-22.72-22.15c-5.6-9.31-8.41-19.65-8.41-31.01
			c0-11.51,2.8-21.92,8.41-31.24c5.6-9.31,13.18-16.66,22.72-22.04c9.54-5.37,20.22-8.07,32.03-8.07c11.96,0,22.72,2.69,32.26,8.07
			c9.54,5.38,17.11,12.72,22.72,22.04c5.6,9.31,8.41,19.73,8.41,31.24c0,11.36-2.8,21.7-8.41,31.01
			c-5.6,9.31-13.18,16.7-22.72,22.15C1792.28,361.75,1781.52,364.47,1769.56,364.47z M1769.56,337.44c6.21,0,11.81-1.55,16.81-4.66
			c5-3.1,8.94-7.23,11.81-12.38c2.88-5.15,4.32-10.98,4.32-17.49c0-6.36-1.48-12.12-4.43-17.27c-2.95-5.15-6.89-9.24-11.81-12.27
			c-4.92-3.03-10.42-4.54-16.47-4.54c-6.21,0-11.81,1.52-16.81,4.54c-5,3.03-8.94,7.12-11.81,12.27c-2.88,5.15-4.32,10.9-4.32,17.27
			c0,6.51,1.44,12.35,4.32,17.49c2.88,5.15,6.78,9.28,11.7,12.38C1757.78,335.89,1763.35,337.44,1769.56,337.44z" />
			<path
				d="M1851.8,408.55V243.84h30.67v9.77c9.54-7.57,20.75-11.36,33.62-11.36c11.21-0.15,21.35,2.5,30.44,7.95
			c9.09,5.45,16.32,12.76,21.7,21.92c5.37,9.17,8.07,19.5,8.07,31.01c0,11.36-2.73,21.62-8.18,30.78
			c-5.45,9.17-12.8,16.44-22.04,21.81c-9.24,5.38-19.54,8.07-30.9,8.07c-6.06,0-11.78-0.83-17.15-2.5
			c-5.38-1.66-10.41-4.01-15.11-7.04v54.3H1851.8z M1911.1,337.44c6.51,0,12.38-1.48,17.61-4.43c5.23-2.95,9.35-7.08,12.38-12.38
			c3.03-5.3,4.54-11.13,4.54-17.49c0-6.51-1.52-12.34-4.54-17.49c-3.03-5.15-7.16-9.24-12.38-12.27c-5.22-3.03-11.1-4.54-17.61-4.54
			c-5.76,0-11.06,0.95-15.9,2.84c-4.85,1.9-8.94,4.74-12.27,8.52v46.12c3.33,3.49,7.46,6.21,12.38,8.18
			C1900.23,336.45,1905.49,337.44,1911.1,337.44z" />
		</g>
	</g>
</svg>

<style>
	svg {
		height: 100%;
	}
</style>
