<script lang="ts">
	import { page } from "$app/stores";
</script>

<svelte:head>
	{#if $page.data.isTestMode}
		<meta name="robots" content="noindex,nofollow" />
	{:else}
		<meta name="robots" content="index,follow" />
	{/if}
</svelte:head>
